import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import CurlExamples from '../_curl-examples.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeSet = makeShortcode("CodeSet");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Geolocating an IP address using GeoIP2 or GeoLite2 web services consists of
configuring a web service client, creating a request, and handling the response.`}</p>
    <div {...{
      "id": "toc-implementation"
    }}><h2 {...{
        "id": "implementation",
        "parentName": "div"
      }}>{`Implementation`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind offers and highly recommends using
`}
        <a {...{
          "href": "/geoip/docs/web-services#client-apis",
          "parentName": "p"
        }}>{`official client libraries`}</a>
        {` to access our
geolocation services. If you cannot or do not wish to use our client libraries,
please review our
`}
        <a {...{
          "href": "/geoip/docs/web-services/#request-and-response-api-references",
          "parentName": "p"
        }}>{`GeoIP2 API Documentation page`}</a>
        {`
for details on our REST API.`}</p>
      <div {...{
        "id": "toc-1-install-the-geoip2-client-library",
        "parentName": "div"
      }}><h3 {...{
          "id": "1-install-the-geoip2-client-library",
          "parentName": "div"
        }}>{`1. Install the GeoIP2 client library`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We have a collection of officially supported libraries for you to interact with
the GeoIP2 and GeoLite2 APIs:`}</p>
        <CodeSet mdxType="CodeSet">
          <pre><code {...{
              "className": "language-cli-csharp",
              "parentName": "pre"
            }}>{`# Install via NuGet
Install-Package MaxMind.GeoIP2
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-java",
              "parentName": "pre"
            }}>{`# Install via Maven, recommended
<dependency>
  <groupId>com.maxmind.geoip2</groupId>
  <artifactId>geoip2</artifactId>
  <version>2.15.0</version>
</dependency>

# Or install via Gradle
repositories {
  mavenCentral()
}
dependencies {
  compile 'com.maxmind.geoip2:geoip2:2.15.0'
}
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-javascript",
              "parentName": "pre"
            }}>{`# Install via npm
npm install @maxmind/geoip2-node

# Or install via yarn
yarn add @maxmind/geoip2-node
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-php",
              "parentName": "pre"
            }}>{`# Install via Composer
composer require geoip2/geoip2:~2.0
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-python",
              "parentName": "pre"
            }}>{`# Install via pip
pip install geoip2
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-ruby",
              "parentName": "pre"
            }}>{`# Install as a gem
gem install maxmind-geoip2

# Or add this to your Gemfile
gem 'maxmind-geoip2'
`}</code></pre>
        </CodeSet></div>
      <div {...{
        "id": "toc-2-create-and-configure-a-geoip2-client-object",
        "parentName": "div"
      }}><h3 {...{
          "id": "2-create-and-configure-a-geoip2-client-object",
          "parentName": "div"
        }}>{`2. Create and configure a GeoIP2 client object`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`To interact with our API, you need to create a new client object. For this you
will need your MaxMind
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`account ID and license key`}</a>
          {`.
Our clients also allow you to interact with our GeoLite2 API, but this requires
additional configuration as demonstrated below:`}</p>
        <CodeSet mdxType="CodeSet">
          <pre><code {...{
              "className": "language-csharp",
              "parentName": "pre"
            }}>{`var client = new WebServiceClient(10, "LICENSEKEY");

// To query the GeoLite2 web service, you must set the optional \`host\` parameter
to \`geolite.info\`
var client = new WebServiceClient(10, "LICENSEKEY", host: "geolite.info");

`}</code></pre>
          <pre><code {...{
              "className": "language-java",
              "parentName": "pre"
            }}>{`WebServiceClient client = new WebServiceClient.Builder(10, "LICENSEKEY").build();

// To query the GeoLite2 web service, you must call the \`host\` method on the
// builder with "geolite.info"
WebServiceClient client = new WebServiceClient.Builder(10, "LICENSEKEY").host("geolite.info").build();
`}</code></pre>
          <pre><code {...{
              "className": "language-javascript",
              "parentName": "pre"
            }}>{`const client = new WebServiceClient('10', 'LICENSEKEY');

// To query the GeoLite2 web service, you must set the optional \`host\` parameter
const client = new WebServiceClient('10', 'LICENSEKEY', {host: 'geolite.info'});
`}</code></pre>
          <pre><code {...{
              "className": "language-php",
              "parentName": "pre"
            }}>{`$client = new Client(10, 'LICENSEKEY');

// To query the GeoLite2 web service, you must set the optional \`host\` argument.
// The third argument specifies the language preferences when using the \`->name\`
// method on the model classes that this client creates.
$client = new Client(10, 'LICENSEKEY', ['en'], ['host' => 'geolite.info']);
`}</code></pre>
          <pre><code {...{
              "className": "language-python",
              "parentName": "pre"
            }}>{`# If you want to use synchronous requests
client = Client(10, 'LICENSEKEY');
# To query the GeoLite2 web service, you must set the "host" keyword argument
# to "geolite.info"
client = Client(10, 'LICENSEKEY', host='geolite.info');

# Or if you want to use asynchronous requests
async_client = AsyncClient(10, 'LICENSEKEY');

# To query the GeoLite2 web service, you must set the "host" keyword argument
# to "geolite.info"
async_client = AsyncClient(10, 'LICENSEKEY', host='geolite.info');
`}</code></pre>
          <pre><code {...{
              "className": "language-ruby",
              "parentName": "pre"
            }}>{`Minfraud.configure do |c|
  c.account_id = 10
  c.license_key = 'LICENSEKEY'

  # To use the GeoLite2 web service instead of GeoIP2, set the host
  # parameter to "geolite.info", eg:
  # host: 'geolite.info'
end
`}</code></pre>
        </CodeSet></div>
      <div {...{
        "id": "toc-3-query-the-desired-geolocation-service",
        "parentName": "div"
      }}><h3 {...{
          "id": "3-query-the-desired-geolocation-service",
          "parentName": "div"
        }}>{`3. Query the desired geolocation service`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`GeoIP2 offers 3 services: Insights, City Plus, and Country. GeoLite2 offers 2
services: City and Country. Each client library has an appropriately named
method for accessing the desired geolocation service.`}</p>
        <CodeSet mdxType="CodeSet">
          <pre><code {...{
              "className": "language-csharp",
              "parentName": "pre"
            }}>{`// If you are making multiple requests, a single WebServiceClient
// should be shared across requests to allow connection reuse. The
// class is thread safe.

// Sync
using (var client = new WebServiceClient(10, "license_key"))
{
    // You can also use \`client.City\` or \`client.Insights\`
    // \`client.Insights\` is not available to GeoLite2 users
    var response = client.Country("128.101.101.101");

    Console.WriteLine(response.Country.IsoCode);        // 'US'
    Console.WriteLine(response.Country.Name);           // 'United States'
    Console.WriteLine(response.Country.Names["zh-CN"]); // '美国'
}

// Async
using (var client = new WebServiceClient(10, "license_key"))
{
    // You can also use \`client.CityAsync\` or \`client.InsightsAsync\`
    // \`client.InsightsAsync\` is not available to GeoLite2 users
    var response = await client.CountryAsync("128.101.101.101");

    Console.WriteLine(response.Country.IsoCode);        // 'US'
    Console.WriteLine(response.Country.Name);           // 'United States'
    Console.WriteLine(response.Country.Names["zh-CN"]); // '美国'
}
`}</code></pre>
          <pre><code {...{
              "className": "language-java",
              "parentName": "pre"
            }}>{`try (WebServiceClient client = new WebServiceClient.Builder(42, "license_key")
        .build()) {

    InetAddress ipAddress = InetAddress.getByName("128.101.101.101");

    // You can also use \`client.city\` or \`client.insights\`
    // \`client.insights\` is not available to GeoLite2 users
    CountryResponse response = client.country(ipAddress);

    Country country = response.getCountry();
    System.out.println(country.getIsoCode());            // 'US'
    System.out.println(country.getName());               // 'United States'
    System.out.println(country.getNames().get("zh-CN")); // '美国'
}
`}</code></pre>
          <pre><code {...{
              "className": "language-javascript",
              "parentName": "pre"
            }}>{`const WebServiceClient = require('@maxmind/geoip2-node').WebServiceClient;
// Typescript:
// import { WebServiceClient } from '@maxmind/geoip2-node';

// You can also use \`client.city\` or \`client.insights\`
// \`client.insights\` is not available to GeoLite2 users
client.country('142.1.1.1').then(response => {
  console.log(response.country.isoCode); // 'CA'
});
`}</code></pre>
          <pre><code {...{
              "className": "language-php",
              "parentName": "pre"
            }}>{`<?php require_once 'vendor/autoload.php'
use GeoIp2\\WebService\\Client;

$client = new Client(10, 'LICENSEKEY');

// You can also use \`$client->city\` or \`$client->insights\`
// \`$client->insights\` is not available to GeoLite2 users
$record = $client->country('128.101.101.101');

print($record->country->isoCode . "\\n");
`}</code></pre>
          <pre><code {...{
              "className": "language-python",
              "parentName": "pre"
            }}>{`# Sync
import geoip2.webservice

with geoip2.webservice.Client(10, 'license_key') as client:
  # You can also use \`client.city\` or \`client.insights\`
  # \`client.insights\` is not available to GeoLite2 users
  response = client.country('128.101.101.101)

  print(response.country.iso_code)

# Async
import asyncio
import geoip2.webservice

async def main():
  async with geoip2.webservice.AsyncClient(10, 'license_key') as client:
    # You can also use \`client.city\` or \`client.insights\`
    # \`client.insights\` is not available to GeoLite2 users
    response = await client.country('128.101.101.101)

    print(response.country.iso_code)

asyncio.run(main())
`}</code></pre>
          <pre><code {...{
              "className": "language-ruby",
              "parentName": "pre"
            }}>{`require 'maxmind/geoip2'

Minfraud.configure do |c|
  c.account_id = 10
  c.license_key = 'LICENSEKEY'
end

# You can also use \`client.city\` or \`client.insights\`
# \`client.insights\` is not available to GeoLite2 users
record = client.country('128.101.101.101')

puts record.country.iso_code
`}</code></pre>
        </CodeSet></div></div>
    <div {...{
      "id": "toc-client-apis"
    }}><h2 {...{
        "id": "client-apis",
        "parentName": "div"
      }}>{`Client APIs`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`You can find a complete list of official and third-party client APIs on the
`}
        <a {...{
          "href": "/geoip/docs/web-services#client-apis",
          "parentName": "p"
        }}>{`web services documentation page`}</a>
        {`.`}</p></div>
    <div {...{
      "id": "toc-command-line-curl-examples"
    }}><h2 {...{
        "id": "command-line-curl-examples",
        "parentName": "div"
      }}>{`Command Line (curl) Examples`}</h2>
      <CurlExamples mdxType="CurlExamples" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      